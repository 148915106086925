import React, { useState, useEffect } from 'react';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { 
  Box, 
  Typography, 
  TableContainer, 
  Table, 
  TableHead, 
  TableRow, 
  TableCell, 
  TableBody, 
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Collapse,
  Grid,
  Card,
  CardContent,
  CircularProgress,


} from "@mui/material";
import useWindowSize from './useWindowSize';
import theme_principal from "../themes";
import { useUser } from "../userContext";

const Dashboard = ({suscripciones}) => {
  const { user } = useUser();
  const currentDate = new Date();
  const [selectedMonth, setSelectedMonth] = useState(currentDate.getMonth());
  const [selectedYear, setSelectedYear] = useState(currentDate.getFullYear());
  const [showDemos, setShowDemos] = useState(false);
  const [distributors, setDistributors] = useState([]);
  const [selectedDistributor, setSelectedDistributor] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});

  const windowSize = useWindowSize();
  const isMobile = windowSize.width < 600;
  const data = [
    { name: 'Activas', Cantidad: suscripciones.activas ? suscripciones.activas : 0, filterData: 'Activa'},
    { name: 'Demo', Cantidad: suscripciones.demo ? suscripciones.demo : 0, filterData: 'Demo' },
    { name: 'Suspendidas', Cantidad: suscripciones.suspendidas ? suscripciones.suspendidas : 0, filterData: 'Suspendida' },
    { name: 'Canceladas', Cantidad: suscripciones.canceladas ? suscripciones.canceladas : 0, filterData: 'Cancelada' },
    { name: 'Expiradas', Cantidad: suscripciones.expiradas ? suscripciones.expiradas : 0, filterData: 'Expirada'},
  ];
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF8042'];

  const total = data.reduce((acc, item) => acc + item.Cantidad, 0);

  const handleClick = (filterData) => {
    // Redirigir a /empresas mandando el dato de filterData
    if (filterData === "Todos") {
      filterData = "";
    }
    window.location.href = `/empresas?filtro=${filterData}`;
  };

  useEffect(() => {
    fetchData();
  }, [selectedMonth, selectedYear, user]);

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL_ADMIN + "/suscripciones/distribuidor",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            idusr: user.id,
            pemth: selectedMonth + 1,
            peyr: selectedYear,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setDistributors(data);
      if (data.length > 0) {
        setSelectedDistributor(data[0].nomusr);
      }
    } catch (err) {
      setError(err.message);
      console.error("Error al obtener datos de los distribuidores:", err);
    } finally {
      setLoading(false);
    }
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
    fetchData();
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    fetchData();
  };

  const handleDistributorChange = (event) => {
    setSelectedDistributor(event.target.value);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [index]: !prevState[index]
    }));
  };

  const getStatusText = (status) => {
    const statusMap = {
      'A': 'Activo',
      'C': 'Cancelado',
      'S': 'Suspendido',
      'E': 'Expirado',
      'P': 'En proceso de pago'
    };
    return statusMap[status] || status;
  };

  const calculateTotals = (companies) => {
    let totalPaid = 0;
    let totalPending = 0;

    companies.forEach(company => {
      const amount = company.imp + (company.disp * company.precdisp);
      if (company.est === 'A' || company.est === 'E') {
        totalPaid += amount;
      } else {
        totalPending += amount;
      }
    });

    return { totalPaid, totalPending };
  };

  const selectedDistributorData = distributors.find(d => d.nomusr === selectedDistributor);
  const { totalPaid, totalPending } = calculateTotals(selectedDistributorData?.empresas || []);

  const cardStyle = {
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  };
  const containerStyle = {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    gap: '20px',
  };

  const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  const pieChartContainerStyle = {
    flex: 1,
    minHeight: isMobile ? '200px' : '300px',
    height: '100%',
    width: '100%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const pieChartStyle = {
    flex: 1,
    minHeight: isMobile ? '200px' : '300px',
    height: '100%',
    width: '100%',
  };


  return (
    <Box sx={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
      <Typography variant="h4" gutterBottom>Suscripciones</Typography>

      <Grid container spacing={3}>
        {/* Resumen y Gráfica en la misma fila para pantallas grandes */}
        <Grid item xs={12} md={6}>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="h5" gutterBottom>Resumen</Typography>
              {data.map((item) => (
                <Box
                  key={item.name}
                  sx={{ 
                    display: 'flex', 
                    justifyContent: 'space-between', 
                    marginBottom: '10px', 
                    cursor: 'pointer' 
                  }}
                  onClick={() => handleClick(item.filterData)}
                >
                  <Typography>{item.name}:</Typography>
                  <Typography>{item.Cantidad}</Typography>
                </Box>
              ))}
              <Box 
                sx={{ 
                  display: 'flex', 
                  justifyContent: 'space-between', 
                  fontWeight: 'bold', 
                  cursor: 'pointer',
                  marginTop: '10px',
                  paddingTop: '10px',
                  borderTop: '1px solid #ddd'
                }}
                onClick={() => handleClick("Todos")}
              >
                <Typography>Total:</Typography>
                <Typography>{total}</Typography>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{...cardStyle, height: '100%'}}>
            <CardContent>
              <Typography variant="h5" gutterBottom>Distribución de Suscripciones</Typography>
              <Box sx={{ height: 300, width: '100%' }}>
                <ResponsiveContainer>
                  <PieChart>
                    <Pie
                      data={data}
                      dataKey="Cantidad"
                      nameKey="name"
                      cx="50%"
                      cy="50%"
                      outerRadius={isMobile ? 60 : 100}
                      fill="#8884d8"
                      label
                      onClick={(e, index) => handleClick(data[index].filterData)}
                    >
                      {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                    <Legend verticalAlign="bottom" height={36} />
                  </PieChart>
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Tabla de cargos en una fila separada */}
        {loading ? 
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
        : 
        <Grid item xs={12}>
          <Card sx={cardStyle}>
            <CardContent>
              <Typography variant="h5" gutterBottom>
                Distribuidor: 
                <Select value={selectedDistributor} onChange={handleDistributorChange} sx={{ ml: 2 }}>
                  {distributors.map((distributor) => (
                    <MenuItem key={distributor.nomusr} value={distributor.nomusr}>
                      {distributor.nomusr}
                    </MenuItem>
                  ))}
                </Select>
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                <Box>
                  <Typography variant="subtitle1" gutterBottom>
                    Periodo:
                  </Typography>
                  <Box sx={{ display: 'flex', gap: 2 }}>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel>Mes</InputLabel>
                      <Select value={selectedMonth} onChange={handleMonthChange} label="Mes">
                        {months.map((month, index) => (
                          <MenuItem key={index} value={index}>{month}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120 }}>
                      <InputLabel>Año</InputLabel>
                      <Select value={selectedYear} onChange={handleYearChange} label="Año">
                        {[2023, 2024, 2025].map((year) => (
                          <MenuItem key={year} value={year}>{year}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              {/*   <FormControlLabel
                  control={<Checkbox checked={showDemos} onChange={(e) => setShowDemos(e.target.checked)} />}
                  label="Ver demos"
                /> */}
              </Box>
              <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: theme_principal.palette.primary.light }}>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Suscripción</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Empresa</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Correo</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Fecha Pago</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Estatus</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Importe</TableCell>
                      <TableCell sx={{ fontWeight: "bold", color: "#fff" }}>Tipo</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedDistributorData?.empresas.map((company, index) => (
                      <React.Fragment key={index}>
                        <TableRow onClick={() => toggleRowExpansion(index)} style={{ cursor: 'pointer' }}>
                          <TableCell>{company.susc}</TableCell>
                          <TableCell>{company.emp}</TableCell>
                          <TableCell>{company.usr}</TableCell>
                          <TableCell>{company.fechapago}</TableCell>
                          <TableCell>{getStatusText(company.est)}</TableCell>
                          <TableCell>${company.imp.toFixed(2)}</TableCell>
                          <TableCell>{company.tipo}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                            <Collapse in={expandedRows[index]} timeout="auto" unmountOnExit>
                              <Box margin={1}>
                                <Typography variant="h6" gutterBottom component="div">
                                  Extras
                                </Typography>
                                <Table size="small" aria-label="extras">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell>Cantidad</TableCell>
                                      <TableCell>Precio Unitario</TableCell>
                                      <TableCell>Total</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>{company.disp}</TableCell>
                                      <TableCell>${company.precdisp.toFixed(2)}</TableCell>
                                      <TableCell>${(company.disp * company.precdisp).toFixed(2)}</TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      </React.Fragment>
                    ))}
                  </TableBody>
                  <TableBody>
                    <TableRow>
                      <TableCell colSpan={5} />
                      <TableCell sx={{ fontWeight: 'bold' }}>Total Pagado:</TableCell>
                      <TableCell>${totalPaid.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={5} />
                      <TableCell sx={{ fontWeight: 'bold' }}>Total Pendiente:</TableCell>
                      <TableCell>${totalPending.toFixed(2)}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>}
      </Grid>
                  
    </Box>
  );
};

export default Dashboard;