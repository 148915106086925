import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  TextField,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  TableSortLabel,
  Modal,
  Backdrop,
  Fade,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  Divider, 
  Grid,
  Alert,
  Snackbar,
  Container,
  Button

} from "@mui/material";
import { Search } from "@mui/icons-material";
import theme_principal from "../themes";
import { useUser } from "../userContext";
import { useLocation } from 'react-router-dom';
import MultiSelect from "../components/multi_select";
import { Edit, Close,Save } from '@mui/icons-material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import esLocale from 'date-fns/locale/es';

dayjs.locale('es');

const EmpresaDetalles = () => {
  const location = useLocation();

  const { user } = useUser();
  const [searchTerm, setSearchTerm] = useState("");
  const [empresasData, setEmpresasData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [orderBy, setOrderBy] = useState("");
  const [order, setOrder] = useState("asc");

  const [selectedClient, setSelectedClient] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const [statusFilter, setStatusFilter] = useState("T");
  const [planFilter, setPlanFilter] = useState([]);
  const [minImporte, setMinImporte] = useState("");
  const [maxImporte, setMaxImporte] = useState("");
  const queryParams = new URLSearchParams(location.search);
  const filtro = queryParams.get('filtro'); // 'filtro' será 'A', 'D', 'S', 'C', 'E'
  const tiposPlanes = ["Anual", "Mensual", "Demo", "Dev"];
  const tiposEstatus = ["Activa", "Suspendida", "Expirada", "Cancelada"];
  const [lDistribuidores, setDistribuidores] = useState([]);
  const [editarSus, setEditarSus] = useState(false);
  const [susChangeData, setSusChangeData] = useState({});
  const [planSuscripcion, setPlanSuscripcion] = useState(selectedClient?.suscripcion.plan || '');
  const [estatusSuscripcion, setEstatusSuscripcion] = useState(selectedClient?.suscripcion.estatus || '');
  const [fechaInicio, setFechaInicio] = useState(selectedClient?.suscripcion.fecha_inicio ? new Date(selectedClient.suscripcion.fecha_inicio) : null);
  const [fechaFin, setFechaFin] = useState(selectedClient?.suscripcion.fecha_fin ? new Date(selectedClient.suscripcion.fecha_fin) : null);

  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('info');


  useEffect(() => {
    const fetchData = async () => {
      setPlanFilter(tiposPlanes)
      if (!user || !user.id) {
        setLoading(false);
        return;
      }

      setError(null);

      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL_ADMIN + "/empresas",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              id_usuario: user.id,
            }),
          }
        );

        if (!response.ok) {
          throw new Error("Sucedió un error al obtener los datos.");
        }
        const data = await response.json();
        console.log(data);
        const sortedData = data
          .sort((a, b) => {
            return a.nombre_empresa.localeCompare(b.nombre_empresa);
          })
          .map(crearItemBusqueda);
        setEmpresasData(sortedData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    if (filtro) {
      if (filtro === 'Demo') {
        setStatusFilter('T');
        setPlanFilter(['Demo']);
        return;
      }
      if (filtro === 'Activa'){
        setPlanFilter(tiposPlanes.filter(plan => plan !== 'Demo'));
      }
      
      setStatusFilter(filtro);
    }

  }, [user]);

  
  const handleAlertClose = (event, reason) => {
    
    setAlertOpen(false);
  };

  const showAlert = (message, severity) => {
    setAlertMessage(message);
    setAlertSeverity(severity);
    setAlertOpen(true);
  };

  function actualizarSuscripcion() {
    if (!selectedClient) return;
    if (selectedClient.suscripcion.plan != 'Demo'){
      //Alerta de que solo se pueden editar suscripciones demo por el momento
      showAlert('Solo se pueden editar suscripciones demo por el momento', 'warning');


      return;
    }
    if(editarSus){
      updateSuscripcion();

    }
    setEditarSus(!editarSus);
  
  }

  function cancelUpdate(){
    setEditarSus(false);
  }
  function updateSuscripcion(){
    showAlert('Suscripción actualizada con éxito', 'success');

  }
  const handlePlanChange = (event) => {
    setPlanSuscripcion(event.target.value);
    if(event.target.value === "Dev"){
      // Alerta de que se cambiara el tipo pero no se hara ningun cobro, ni creara una suscripcion real
      showAlert('Se cambiara dev pero ya no se podrá ver en la lista, asegúrate de que sea lo correcto.', 'info');
      
    }else
    if(event.target.value != "Demo"){
      // Alerta de que se cambiara el tipo pero no se hara ningun cobro, ni creara una suscripcion real
      showAlert('Se cambiará el tipo pero no se hará ningún cobro, ni creará una suscripción real.', 'info');
      
    }
    
    //
  };

  const handleEstatusChange = (event) => {
    setEstatusSuscripcion(event.target.value);
  };

  function crearItemBusqueda(empresa) {
    const campos = [
      empresa.nombre_empresa,
      empresa.usuario,
      empresa.distribuidor,
      empresa.suscripcion.plan,
      empresa.suscripcion.cantidad_dispositivos,
      empresa.suscripcion.estatus,
      formatDate(empresa.suscripcion.fecha_inicio),
      formatDate(empresa.suscripcion.fecha_fin),
      formatCurrency(empresa.suscripcion.importe),
    ];
    empresa.busqueda = campos.join(" ").toLowerCase();
    return empresa;
  }

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filtroDesdeDashboard = (filtro) => {
    setSearchTerm(filtro);
  }

  const handleSort = (column, suscripcion) => {
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);

    const sortedData = [...empresasData].sort((a, b) => {
      if (suscripcion) {
        if (
          a.suscripcion[column].toLowerCase() <
          b.suscripcion[column].toLowerCase()
        )
          return isAsc ? -1 : 1;
        if (
          a.suscripcion[column].toLowerCase() >
          b.suscripcion[column].toLowerCase()
        )
          return isAsc ? 1 : -1;
      } else {
        if (a[column].toLowerCase() < b[column].toLowerCase()) return isAsc ? -1 : 1;
        if (a[column].toLowerCase() > b[column].toLowerCase()) return isAsc ? 1 : -1;
      }
      return 0;
    });

    setEmpresasData(sortedData);
  };

  const formatDate = (date) => {
    if (!date) return " - ";

    const [year, month, day] = date.split("-");
    const año = parseInt(year, 10);
    const mes = parseInt(month, 10) - 1;
    const dia = parseInt(day, 10);

    if (isNaN(año) || isNaN(mes) || isNaN(dia)) {
      return " - " + date;
    }

    const newDate = new Date(año, mes, dia);
    return newDate.toLocaleDateString("es-MX", {
      day: "numeric",
      month: "short",
      year: "numeric",
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat("es-MX", {
      style: "currency",
      currency: "MXN",
    }).format(value);
  };

  const highlightText = (text, highlight) => {
    if (!highlight) return text;

    const stringText = text.toString();
    const parts = stringText.split(new RegExp(`(${highlight})`, "gi"));
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} style={{ backgroundColor: "yellow" }}>
          {part}
        </span>
      ) : (
        part
      )
    );
  };

  const handleStatusFilterChange = (event) => {
    setStatusFilter(event.target.value);
  };

  const handlePlanFilterChange = (selectedPlans) => {
    setPlanFilter(selectedPlans);
  };

  const handleMinImporteChange = (event) => {
    setMinImporte(event.target.value);
  };

  const handleMaxImporteChange = (event) => {
    setMaxImporte(event.target.value);
  };

  const filteredEmpresas = empresasData.filter((empresa) => {
    const matchesSearchTerm = empresa.busqueda.includes(
      searchTerm.toLowerCase()
    );
    const matchesStatus =
      statusFilter === "T" || empresa.suscripcion.estatus === statusFilter;

  
    const matchesPlan = 
      planFilter.length === 0 || planFilter.includes(empresa.suscripcion.plan);
    const matchesImporte =
      (!minImporte || empresa.suscripcion.importe >= parseFloat(minImporte)) &&
      (!maxImporte || empresa.suscripcion.importe <= parseFloat(maxImporte));

    return matchesSearchTerm && matchesStatus && matchesPlan && matchesImporte;
  });

  const paginatedEmpresas = filteredEmpresas.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (empresa) => {
    setSelectedClient(empresa);
    setPlanSuscripcion(empresa.suscripcion.plan);
    setEstatusSuscripcion(empresa.suscripcion.estatus);
    
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setEditarSus(false);
  };

  const calculateTotalImporte = () => {
    return paginatedEmpresas.reduce(
      (total, empresa) => total + empresa.suscripcion.importe,
      0
    );
  };

  return (
    <Box sx={{ p: 2 }}>
      <Box sx={{ textAlign: "left", mb: 1 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ fontWeight: "bold", mb: 1, userSelect: "none" }}
        >
          Suscripciones
        </Typography>
        <Typography
          variant="body1"
          gutterBottom
          sx={{ color: "text.secondary", mb: 2 }}
        >
          Información de las suscripciones de cada cliente.
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 3,
            flexWrap: "wrap",
          }}
        >
          <TextField
            id="search"
            label="Buscar por cualquier campo..."
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ width: "70%", mr: 2 }}
            InputProps={{
              endAdornment: (
                <Search sx={{ color: theme_principal.palette.primary.main }} />
              ),
            }}
          />
          <FormControl sx={{ minWidth: 120, mr: 2 }}>
            <InputLabel>Estatus</InputLabel>
            <Select
              value={statusFilter}
              onChange={handleStatusFilterChange}
              label="Estatus"
            >
              <MenuItem value="T">Todas</MenuItem>
              <MenuItem value="Activa">Activas</MenuItem>
              <MenuItem value="Expirada">Expiradas</MenuItem>
              <MenuItem value="Suspendida">Suspendidas</MenuItem>
              <MenuItem value="Cancelada">Canceladas</MenuItem>
            </Select>
          </FormControl>
          <MultiSelect
            label="Plan"
            options={tiposPlanes}
            selectedOptions={planFilter}
            onChange={handlePlanFilterChange}
          />
{/*           <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            label="Importe Mínimo"
            variant="outlined"
            size="small"
            type="number"
            value={minImporte}
            onChange={handleMinImporteChange}

            sx={{ width: "15%", mr: 2 }}
          />
          <TextField
            label="Importe Máximo"
            variant="outlined"
            size="small"
            type="number"
            value={maxImporte}
            onChange={handleMaxImporteChange}
            sx={{ width: "15%" }}
          />
          </Box>
 */}        </Box>
      </Box>

      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : error ? (
        <Typography variant="body1" color="error">
          {error}
        </Typography>
      ) : (
        <>
          {paginatedEmpresas.length === 0 ? (
            <Typography
              variant="body1"
              color="error"
              sx={{ textAlign: "center", mt: 5 }}
            >
              No se encontraron empresas.
            </Typography>
          ) : (
            <>
              <TableContainer component={Paper} sx={{ boxShadow: 3 }}>
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: theme_principal.palette.primary.light,
                      }}
                    >
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("nombre_empresa", false)}
                        sortDirection={
                          orderBy === "nombre_empresa" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "nombre_empresa"}
                          direction={
                            orderBy === "nombre_empresa" ? order : "asc"
                          }
                        >
                          Cliente
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("plan", true)}
                        sortDirection={orderBy === "plan" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "plan"}
                          direction={orderBy === "plan" ? order : "asc"}
                        >
                          Plan
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() =>
                          handleSort("cantidad_dispositivos", true)
                        }
                        sortDirection={
                          orderBy === "cantidad_dispositivos" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "cantidad_dispositivos"}
                          direction={
                            orderBy === "cantidad_dispositivos" ? order : "asc"
                          }
                        >
                          Dispositivos
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("distribuidor", false)}
                        sortDirection={
                          orderBy === "distribuidor" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "distribuidor"}
                          direction={orderBy === "distribuidor" ? order : "asc"}
                        >
                          Distribuidor
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("estatus", true)}
                        sortDirection={orderBy === "estatus" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "estatus"}
                          direction={orderBy === "estatus" ? order : "asc"}
                        >
                          Estatus
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("fecha_inicio", true)}
                        sortDirection={
                          orderBy === "fecha_inicio" ? order : false
                        }
                      >
                        <TableSortLabel
                          active={orderBy === "fecha_inicio"}
                          direction={
                            orderBy === "fecha_inicio" ? order : "asc"
                          }
                        >
                          Fecha Inicio
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("fecha_fin", true)}
                        sortDirection={orderBy === "fecha_fin" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "fecha_fin"}
                          direction={orderBy === "fecha_fin" ? order : "asc"}
                        >
                          Fecha Fin
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        align="right"
                        sx={{
                          fontWeight: "bold",
                          color: "#fff",
                          cursor: "pointer",
                          userSelect: "none",
                        }}
                        onClick={() => handleSort("importe", true)}
                        sortDirection={orderBy === "importe" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "importe"}
                          direction={orderBy === "importe" ? order : "asc"}
                        >
                          Importe
                        </TableSortLabel>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {paginatedEmpresas.map((empresa, index) => (
                      <TableRow
                        key={index}
                        hover
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleRowClick(empresa)}
                      >
                        <TableCell size="medium">
                          {highlightText(empresa.nombre_empresa, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(empresa.suscripcion.plan, searchTerm)}
                        </TableCell>
                        <TableCell
                          align="right"
                          size="small"
                          sx={{ width: "5%" }}
                        >
                          {highlightText(
                            empresa.suscripcion.cantidad_dispositivos,
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(empresa.distribuidor, searchTerm)}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            empresa.suscripcion.estatus,
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            formatDate(empresa.suscripcion.fecha_inicio),
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell>
                          {highlightText(
                            formatDate(empresa.suscripcion.fecha_fin),
                            searchTerm
                          )}
                        </TableCell>
                        <TableCell align="right">
                          {highlightText(
                            formatCurrency(empresa.suscripcion.importe),
                            searchTerm
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={7} align="right" sx={{ fontWeight: "bold" }}>
                        Total:
                      </TableCell>
                      <TableCell align="right" sx={{ fontWeight: "bold" }}>
                        {formatCurrency(calculateTotalImporte())}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={filteredEmpresas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página"
              />
            </>
          )}
        </>
      )}
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">

<Modal
      open={modalOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <Fade in={modalOpen}>
        <Paper
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "90%",
            maxWidth: 600,
            maxHeight: "90vh",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            overflow: "auto",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          
          {selectedClient && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h5" component="h2" gutterBottom sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                Detalles del Cliente
              </Typography>
              
              <Divider sx={{ my: 2 }} />
              
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nombre:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.nombre_empresa}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Nombre comercial:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.nombre_comercial}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Dirección:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.direccion}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Email:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.correo_usuario}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>RFC:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.rfc}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Régimen fiscal:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.reg_fiscal}</Typography>
                </Grid>
              </Grid>
              
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 4, mb: 2 }}>
                <Typography variant="h5" component="h3" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  Datos de la Suscripción
                </Typography>
                <IconButton 
                onClick={() => actualizarSuscripcion()}
                aria-label="edit subscription" size="small" sx={{ ml: 1 }}>
                  {editarSus ? 
                    <Save fontSize="small" /> 
                   : <Edit fontSize="small" />}
                  
                </IconButton>
                {editarSus && 
                <IconButton onClick={() => cancelUpdate()} aria-label="cancel update" size="small" sx={{ ml: 1 }}>
                  <Close fontSize="small" />
                </IconButton>
                
                }
                    
              </Box>
              
              <Divider sx={{ my: 2 }} />
              {!editarSus ? 
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Tipo de suscripción:</Typography>
                  <Typography variant="body1" gutterBottom>
                    {selectedClient.suscripcion.plan} - {selectedClient.suscripcion.estatus}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Importe:</Typography>
                  <Typography variant="body1" gutterBottom>{formatCurrency(selectedClient.suscripcion.importe)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Inicio de suscripción:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.fecha_inicio)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Fin de suscripción:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.fecha_fin)}</Typography>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Fecha último cobro:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.ultimo_cobro)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Fecha último pago:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.fecha_ultimo_pago)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Distribuidor:</Typography>
                  <Typography variant="body1" gutterBottom>{selectedClient.distribuidor}</Typography>
                </Grid>
              </Grid>
            :
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                    <InputLabel id="tipo-suscripcion-label">Tipo de suscripción</InputLabel>
                    <Select
                      labelId="tipo-suscripcion-label"
                      id="tipo-suscripcion-select"
                      value={planSuscripcion}
                      label="Tipo de suscripción"
                      onChange={handlePlanChange}
                    >
                      {tiposPlanes.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
              <FormControl fullWidth size="small">
                    <InputLabel id="tipo-suscripcion-label">Estatus</InputLabel>
                    <Select
                      labelId="tipo-suscripcion-label"
                      id="tipo-suscripcion-select"
                      value={estatusSuscripcion}
                      label="Tipo de suscripción"
                      onChange={handleEstatusChange}
                    >
                      {tiposEstatus.map((tipo) => (
                        <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>              
               
                <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Inicio de suscripción"
                      value={fechaInicio}
                      onChange={(newValue) => setFechaInicio(newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                      disabled={!editarSus}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Fin de suscripción"
                      value={fechaFin}
                      onChange={(newValue) => setFechaFin(newValue)}
                      renderInput={(params) => <TextField {...params} fullWidth size="small" />}
                      disabled={!editarSus}
                    />
                  </Grid>

              <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Importe:</Typography>
                  <Typography variant="body1" gutterBottom>{formatCurrency(selectedClient.suscripcion.importe)}</Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Fecha último cobro:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.ultimo_cobro)}</Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>Fecha último pago:</Typography>
                  <Typography variant="body1" gutterBottom>{formatDate(selectedClient.suscripcion.fecha_ultimo_pago)}</Typography>
                </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Distribuidor"
                  variant="outlined"
                  size="small"
                  value={selectedClient.distribuidor}
                  sx={{ width: "100%", mr: 2 }}
                />
              </Grid>
            </Grid>
            }
            </Box>
          )}
          <Snackbar open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
            <Alert onClose={handleAlertClose} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>

        </Paper>
      </Fade>
    </Modal>
    </LocalizationProvider>

    </Box>
  );
};

export default EmpresaDetalles;
